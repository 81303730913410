import truckApi from '@/api/truck.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapState, mapMutations, mapGetters } from 'vuex'
import truckSummaryReportService from '../../../../../business/truckSummaryReportService'

export default {
  name: 'TruckSummaryReportDetailComponent',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    loadingTable: true,
    intervalId: null
  }),
  created () {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    if (this.selectedItem) {
      this.getData()
    }
  },
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    })
  },
  beforeDestroy () {
    this.resetEntitySelector()
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('reportDefer', {
      commitVisible: 'commitVisible',
      closeReportDefer: 'closeReportDefer'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    refreshTable () {
      console.log('refresh table')
      if (this.selectedItem) {
        this.getData(true)
      }
    },
    async getData (refresh = false) {
      this.getDetail(this.selectedItem._id, this.selectedItem.from, this.selectedItem.to, this.selectedItem.timeFormat, refresh)
      if (this.selectedItem.autoReport) {
        const that = this
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            that.getDetail(that.selectedItem._id, that.selectedItem.type, true)
          }, that.selectedItem.autoReportMinutes * 60 * 1000)
      }
    },
    getDetail (id, from, to, timeFormat) {
      this.loadingTable = true
      if (this.model.loadingSpinner) this.commitVisible({ visible: true })
      truckApi.getSummaryReportTruckDetails(id, from, to, timeFormat).then(response => {
        const processedData = truckSummaryReportService.processReportDetailData(response.data)
        this.setTableFilterRules(processedData.headers)
        this.tableHeaders.cleanAndUpdate(processedData.headers)
        this.tableData.cleanAndUpdate(processedData.data)
        this.loadingTable = false
        if (this.model.loadingSpinner) this.commitVisible({ visible: false })
      })
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) {
        this.getData()
      }
    }
  }
}
