import { FilterType, DataType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const truckHeaders = (lang) => {
  return [
    // 0
    {
      text: i18n.t('truckSummaryReport.HEADERS.FLEET', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'fleetName'
    },
    // 1
    {
      text: i18n.t('truckSummaryReport.HEADERS.TRUCK', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'thingName'
    },
    // 2
    {
      text: i18n.t('truckSummaryReport.HEADERS.ON_ROUTE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'onRoute'
    },
    // 3
    {
      text: i18n.t('truckSummaryReport.HEADERS.IN_HARVEST_FRONT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'inHarvestFront'
    },
    // 4
    {
      text: i18n.t('truckSummaryReport.HEADERS.IN_CANCHON', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'inCanchon'
    },
    // 5
    {
      text: i18n.t('truckSummaryReport.HEADERS.OUT_OF_SERVICE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'outOfService'
    },
    // 6
    {
      text: i18n.t('truckSummaryReport.HEADERS.MOVING', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'movement'
    },
    // 7
    {
      text: i18n.t('truckSummaryReport.HEADERS.STOPPED', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'stopped'
    }
  ]
}

const fleetHeaders = (lang) => {
  return [
    // 0
    {
      text: i18n.t('truckSummaryReport.HEADERS.FLEET', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'fleetName'
    },
    // 1
    {
      text: i18n.t('truckSummaryReport.HEADERS.ON_ROUTE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'onRoute'
    },
    // 2
    {
      text: i18n.t('truckSummaryReport.HEADERS.IN_HARVEST_FRONT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'inHarvestFront'
    },
    // 3
    {
      text: i18n.t('truckSummaryReport.HEADERS.IN_CANCHON', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'inCanchon'
    },
    // 4
    {
      text: i18n.t('truckSummaryReport.HEADERS.OUT_OF_SERVICE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'outOfService'
    },
    // 5
    {
      text: i18n.t('truckSummaryReport.HEADERS.MOVING', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'movement'
    },
    // 6
    {
      text: i18n.t('truckSummaryReport.HEADERS.STOPPED', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'stopped'
    }
  ]
}

const originHeaders = (lang) => {
  return [
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.fleetName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'fleetName'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.truckName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'truckName'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.caneEquipmentName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'caneEquipmentName'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.tractorName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'tractorName'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.harvestFrontName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'harvestFrontName'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.driveUnitName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'driveUnitName'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.harvesterName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'harvesterName'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.tractorDischargeDate', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'dischargeTrackTimestamp'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.startDate', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'startDate'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.disengagementDate', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'disengagementDate'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.endDate', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'endDate'
    },
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.timeInEquipment', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'timeInEquipment'
    }
  ]
}

const detailHeaders = (lang) => {
  return [
    {
      text: i18n.t('truckSummaryReport.Origin.Headers.truckName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'truck'
    },
    {
      text: i18n.t('truckSummaryReport.Detail.Headers.date', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'timestamp'
    },
    {
      text: i18n.t('truckSummaryReport.Detail.Headers.state', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'stateName'
    },
    {
      text: i18n.t('truckSummaryReport.Detail.Headers.latitude', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'latitude'
    },
    {
      text: i18n.t('truckSummaryReport.Detail.Headers.longitude', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'longitude'
    }
    // {
    //     text: i18n.t('truckSummaryReport.Origin.Headers.driveUnitName', { lang }),
    //     align: 'left',
    //     filterType: FilterType.textField,
    //     selected: true,
    //     sortable: true,
    //     type: DataType.string,
    //     value: 'driveUnit',
    // },
    // {
    //     text: i18n.t('truckSummaryReport.Detail.Headers.caneEquipment', { lang }),
    //     align: 'left',
    //     filterType: FilterType.textField,
    //     selected: true,
    //     sortable: true,
    //     type: DataType.string,
    //     value: 'caneEquipment',
    // },
    // {
    //     text: i18n.t('truckSummaryReport.Origin.Headers.harvestFrontName', { lang }),
    //     align: 'left',
    //     filterType: FilterType.textField,
    //     selected: true,
    //     sortable: true,
    //     type: DataType.string,
    //     value: 'workFront',
    // }
  ]
}

export default {
  truckHeaders,
  fleetHeaders,
  originHeaders,
  detailHeaders
}
