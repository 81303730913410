import Vue from 'vue'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import TruckSummaryReportDetailComponent from './detail/TruckSummaryReportDetail.vue'
import OriginReport from '../origin-report/OriginReport.vue'
import truckApi from '@/api/truck.api'
import i18n from '@/i18n'
import fleetApi from '@/api/fleet.api'
import reportStorageApi from '@/api/report-storage.api'
import { filterRulesMapped } from '@/tools/filterRules'
import moment from 'moment'
import deepcopy from 'deepcopy'
import { sortTimeByDay, getChildrenFromList, getDateTimeRange, statisticFunction, selectorDateTimeValidation, ISODateFromUnixTimestamp } from '@/tools/functions'
import { ReportConversionUtil, TimeRanges, CustomTimeRangeTypes, TimeFormat } from '@colven/common-domain-lib/lib'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import truckSummaryReportService from '@/business/truckSummaryReportService'

export default {
  name: 'TruckSummaryReportComponent',
  components: {
    BreadcrumbComponent,
    SelectorComponent,
    TruckSummaryReportDetailComponent,
    OriginReport,
    AutocompleteComponent
  },
  props: {
  },
  data: () => ({
    truckTableDataKey: 'thingId',
    truckTableHeaders: [],
    truckTableFilterRules: {},
    truckTableData: [],
    timeTruckTableData: [],
    percentageTruckTableData: [],
    truckTableButtons: [],
    truckPercentage: false,
    fleetTableDataKey: 'fleetId',
    fleetTableHeaders: [],
    fleetTableFilterRules: {},
    fleetTableData: [],
    timeFleetTableData: [],
    percentageFleetTableData: [],
    fleetTableButtons: [],
    fleetPercentage: false,
    tabs: [
      {
        id: 'tab-summary',
        name: i18n.t('trucks.reports.summary.summary')
      }
    ],
    selectedTab: 'tab-summary',
    breadcrumbButtomsTruckSummaryReport: [],
    showSelector: false,
    selectorDisabled: false,
    selectorModel: {
      showClose: true,
      filters: [
        {
          id: 'trucks',
          name: i18n.t('trucks.title'),
          show: false,
          disabled: false,
          singleSelect: false,
          showDialog: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      disableGetReportButton: true,
      showDateAndTimeSelector: true,
      showTimeFormatSelector: true,
      selectedTimeFormat: {
        value: TimeFormat.HH_MM_SS.id,
        text: TimeFormat.HH_MM_SS.name
      },
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      customDateTimeValidForm: false,
      autoReport: false,
      autoReportMinutes: 10
    },
    reportDetailModel: {
      loadingSpinner: false
    },
    intervalId: null,
    currentReportKey: null,
    disableRefreshButton: true,
    things: [],
    thingsId: [],
    fleets: [],
    fleetsId: [],
    timeStampFrom: moment().unix(),
    timeStampTo: moment().subtract(1, 'hours').unix(),
    dateAndTimeRange: null,
    dateAndTimeRangeCustomType: null,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    timeFormat: null,
    autoReport: false,
    autoReportMinutes: null,
    calcs: [
      {
        id: 1,
        name: i18n.t('table.average')
      }
    ],
    truckLoadingTable: false,
    fleetLoadingTable: false,
    truckSelectorThings: [],
    truckSelectorThingsSelected: null,
    trucksCharts: null,
    selectedTrucksCharts: [],
    fleetSelectorThings: [],
    fleetSelectorThingsSelected: null,
    fleetsCharts: null,
    selectedFleetsCharts: [],
    chartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    loadingOriginTable: false,
    originTableDataKey: 'id',
    originTableHeaders: [],
    originTableData: [],
    originTabClickedOneTime: false,
    originTabDisabled: true,
    originTableDisableRefreshButton: true,
    reportTabSelected: 'tab-summary',
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('navbar.categories.transporte.submenu.resumencamiones.title')}</b>` }]
  }),
  created () {
    this.breadcrumbButtomsTruckSummaryReport.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: this.showSelectorButton.bind(this),
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
    this.truckTableButtons.push(
      {
        id: 'truck-percentage',
        tooltip: i18n.t('trucks.reports.summary.switchToPercentage'),
        icon: 'mdi-percent',
        action: this.truckSwitchPercentage.bind(this),
        selectedData: false
      })
    this.fleetTableButtons.push(
      {
        id: 'fleet-percentage',
        tooltip: i18n.t('trucks.reports.summary.switchToPercentage'),
        icon: 'mdi-percent',
        action: this.fleetSwitchPercentage.bind(this),
        selectedData: false
      })
  },
  async mounted () {
    const meta = this.$route.meta
    this.selectedTab = meta.tab
    // Inicializo los datos del selector
    await this.initializeSelector()
    // Cargar reporte diferido
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  methods: {
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    /**
     * Inicializador de los datos del selector
     */
    async initializeSelector () {
      const response = await fleetApi.getNested('TRANSPORT')
      this.selectorModel.filters[0].data.cleanAndUpdate(response.data)
    },
    getData (reportKey) {
      if (reportKey) {
        this.currentReportKey = reportKey
        // this.selectorDisabled = true
        this.showSelector = false
        this.truckLoadingTable = true
        this.fleetLoadingTable = true
        this.commitVisible({ visible: true })
        reportStorageApi.getReport(reportKey).then(response => {
          const { data, filters } = response.data
          const processedData = truckSummaryReportService.processReportData(data, filters.from, filters.to, filters.timeFormat)
          this.truckTableHeaders.cleanAndUpdate(processedData.truckHeaders)
          this.truckTableData.cleanAndUpdate(processedData.truckData)
          this.timeTruckTableData.cleanAndUpdate(processedData.truckData)
          this.percentageTruckTableData.cleanAndUpdate(processedData.percentageTruckData)
          this.setTruckTableFilterRules(processedData.truckHeaders)
          this.fleetTableHeaders.cleanAndUpdate(processedData.fleetHeaders)
          this.fleetTableData.cleanAndUpdate(processedData.fleetData)
          this.timeFleetTableData.cleanAndUpdate(processedData.fleetData)
          this.percentageFleetTableData.cleanAndUpdate(processedData.percentageFleetData)
          this.trucksCharts = []
          this.fleetsCharts = []
          this.trucksCharts.cleanAndUpdate(processedData.truckCharts)
          this.fleetsCharts.cleanAndUpdate(processedData.fleetCharts)
          this.initCharts()
          this.setFleetTableFilterRules(processedData.fleetHeaders)
          this.setSortFunctions()
          this.initializeSelectorData(filters)
          this.truckLoadingTable = false
          this.fleetLoadingTable = false
          this.commitVisible({ visible: false })
          this.disableRefreshButton = true
          this.originTabDisabled = false
        })
      } else {
        this.selectorDisabled = false
        this.showSelector = true
        this.originTabDisabled = true
        this.originTabClickedOneTime = false
      }
    },
    getReport (eventData) {
      // Se resetea el breadcrumb al generar nuevo reporte
      this.tabs = [
        {
          id: 'tab-summary',
          name: i18n.t('trucks.reports.summary.summary')
        }
      ]
      if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
        this.$store.state.app.entitySelectorItems = []
      }
      this.selectedTab = 'tab-summary'

      this.currentReportKey = null
      this.things = []
      this.thingsId = []
      this.fleets = []
      this.fleetsId = []
      this.truckSelectorThings = []
      this.truckSelectorThingsSelected = null
      this.fleetSelectorThings = []
      this.fleetSelectorThingsSelected = null
      const nestedThings = eventData.filters[0].selectedData
      this.fleets.cleanAndUpdate(nestedThings.filter(t => t.children && t.children.length > 0))
      this.fleets.forEach(fleet => {
        this.fleetsId.push({
          fleetId: fleet.id,
          trucks: fleet.children.map(truck => truck.id)
        })
      })
      getChildrenFromList(nestedThings, this.things)
      this.thingsId = this.things.map(t => t.id)
      this.timeFormat = eventData.dateAndTimeFormat
      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      this.dateAndTimeRange = eventData.dateAndTimeRange
      this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
      this.sinceDate = eventData.sinceDate
      this.sinceTime = eventData.sinceTime
      this.toDate = eventData.toDate
      this.toTime = eventData.toTime
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      this.autoReport = eventData.autoReport
      this.autoReportMinutes = eventData.autoReportMinutes
      this.originTabClickedOneTime = false
      const that = this
      if (eventData.autoReport) {
        that.disableRefreshButton = false
        that.setReportDetails()
        that.getDataNotDeferred()
        clearInterval(that.intervalId)
        that.intervalId = setInterval(
          function () {
            const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
              eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
            that.timeStampFrom = conversion.tFrom
            that.timeStampTo = conversion.tTo
            that.getDataNotDeferred(true)
          },
          eventData.autoReportMinutes * 60 * 1000)
      } else {
        const filters = {
          nestedThings,
          thingsId: this.thingsId,
          fleetsId: this.fleetsId,
          things: this.things,
          fleets: this.fleets,
          from: this.timeStampFrom,
          to: this.timeStampTo,
          timeFormat: this.timeFormat,
          dateTimeRange: this.dateAndTimeRange,
          customDateTimeRangeType: this.dateAndTimeRangeCustomType,
          sinceDate: this.sinceDate,
          sinceTime: this.sinceTime,
          toDate: this.toDate,
          toTime: this.toTime,
          route: this.$route.path
        }
        truckApi.getSummaryReportDeferred(filters)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.showSelector = false
    },
    setReportDetails () {
      this.setDetails({
        detailData: {
          things: this.things,
          fleets: this.fleets,
          from: this.timeStampFrom,
          to: this.timeStampTo,
          reportName: this.reportNameForChart
        },
        dataStructure: [
          { detailDataProp: 'reportName', propTitle: this.$t('report'), propType: 'LIST', propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' } },
          { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
          { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' },
          { detailDataProp: 'things', propTitle: this.$t('trucks.title'), propType: 'LIST', propRefs: { list: this.things, value: 'id', text: 'name' } },
          { detailDataProp: 'fleets', propTitle: this.$t('fleets'), propType: 'LIST', propRefs: { list: this.fleets, value: 'id', text: 'name' } }
        ]
      })
    },
    getDataNotDeferred (refresh = false) {
      this.commitVisible({ visible: true })
      if (this.reportTabSelected === 'tab-summary') {
        this.truckLoadingTable = true
        this.fleetLoadingTable = true
        truckApi.getSummaryReportNotDeferred(this.thingsId, this.fleetsId, this.timeStampFrom, this.timeStampTo, this.timeFormat)
          .then(response => {
            const processedData = truckSummaryReportService.processReportData(response.data, this.timeStampFrom, this.timeStampTo, this.timeFormat)
            if (!refresh) {
              this.truckTableHeaders.cleanAndUpdate(processedData.truckHeaders)
              this.setTruckTableFilterRules(processedData.truckHeaders)
              this.fleetTableHeaders.cleanAndUpdate(processedData.fleetHeaders)
              this.setFleetTableFilterRules(processedData.fleetHeaders)
              this.setSortFunctions()
            }
            this.timeTruckTableData.cleanAndUpdate(processedData.truckData)
            this.percentageTruckTableData.cleanAndUpdate(processedData.percentageTruckData)
            if (this.truckPercentage) this.truckTableData.cleanAndUpdate(this.percentageTruckTableData)
            else this.truckTableData.cleanAndUpdate(this.timeTruckTableData)
            this.timeFleetTableData.cleanAndUpdate(processedData.fleetData)
            this.percentageFleetTableData.cleanAndUpdate(processedData.percentageFleetData)
            if (this.fleetPercentage) this.fleetTableData.cleanAndUpdate(this.percentageFleetTableData)
            else this.fleetTableData.cleanAndUpdate(this.timeFleetTableData)
            this.trucksCharts = []
            this.fleetsCharts = []
            this.trucksCharts.cleanAndUpdate(processedData.truckCharts)
            this.fleetsCharts.cleanAndUpdate(processedData.fleetCharts)
            this.initCharts()
            this.truckLoadingTable = false
            this.fleetLoadingTable = false
            this.commitVisible({ visible: false })
            this.originTabDisabled = false
          })
      } else if (this.reportTabSelected === 'tab-origin') {
        this.originTableDisableRefreshButton = true
        this.loadingOriginTable = true
        truckApi.getOriginReportDirect(this.thingsId, this.timeStampFrom, this.timeStampTo, this.timeFormat)
          .then(response => {
            const processedData = truckSummaryReportService.processOriginData(response.data, this.timeFormat)
            this.originTableData.cleanAndUpdate(processedData.data)
            if (!refresh) {
              this.originTableHeaders.cleanAndUpdate(processedData.headers)
            }
            this.commitVisible({ visible: false })
            this.originTableDisableRefreshButton = false
            this.loadingOriginTable = false
          })
      }
    },
    fullFleet (fleet) {
      const selectorDataFleet = this.selectorFilters[0].data.find(f => f.id === fleet.id)
      return selectorDataFleet.children.length === fleet.children.length
    },
    setTruckTableFilterRules (headers) {
      this.truckTableFilterRules = {}
      headers.forEach(header => {
        this.truckTableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    setFleetTableFilterRules (headers) {
      this.fleetTableFilterRules = {}
      headers.forEach(header => {
        this.fleetTableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    /**
     * TO DO: inicializar los datos de fechas y horas en el selector
     * @param {*} filterData
     */
    initializeSelectorData (filterData) {
      this.things = []
      this.things = filterData.things
      this.thingsId = filterData.thingsId
      this.fleets = filterData.fleets
      this.fleetsId = filterData.fleetsId
      this.selectorModel.filters[0].selectedData.cleanAndUpdate(filterData.nestedThings)
      this.timeStampFrom = filterData.from
      this.timeStampTo = filterData.to
      this.selectorModel.selectedDateAndTimeRange = filterData.dateTimeRange
      this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.customDateTimeRangeType
      this.selectorModel.sinceDate = filterData.sinceDate
      this.selectorModel.sinceTime = filterData.sinceTime
      this.selectorModel.toDate = filterData.toDate
      this.selectorModel.toTime = filterData.toTime
      this.timeFormat = filterData.timeFormat
      this.selectorModel.selectedTimeFormat = deepcopy(this.timeFormat)

      this.setCustomDateAndTimeFilter(filterData)
      this.setReportDetails()
    },
    setCustomDateAndTimeFilter (filterData) {
      if (filterData.from && filterData.to) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        } else if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM &&
          this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
          this.selectorModel.sinceTime = null
          this.selectorModel.toTime = null
        } else {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    resize () {
      this.$eventBus.$emit('resizeCharts')
    },
    tabSelected (id) {
      // Se resetea el breadcrumb al volver al Resumen
      if (id === 'tab-summary') {
        this.tabs = [
          {
            id: 'tab-summary',
            name: i18n.t('trucks.reports.summary.summary')
          }
        ]
        if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
          this.$store.state.app.entitySelectorItems = []
        }
      }
      this.selectedTab = id
    },
    selector () {
      this.showSelector = !this.showSelector
    },
    showSelectorButton () {
      return true
    },
    disableSelectorButton () {
      return this.selectorDisabled
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const thingsSelected = that.selectorModel.filters[0].selectedData
        const timeFormatSelected = that.selectorModel.selectedTimeFormat
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
        that.selectorModel.disableGetReportButton = thingsSelected.length === 0 || !timeFormatSelected || customTimeValidation
      })
    },
    refreshTable () {
      const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
        this.sinceDate, this.sinceTime, this.toDate, this.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      this.getDataNotDeferred(true)
    },
    truckClickRow (data) {
      this.setEntityConfiguration({ name: this.$t('devices'), value: '_id', text: 'name' })
      const items = []
      this.truckTableData.forEach(thing => {
        items.push({
          _id: thing.thingId,
          name: thing.thingName,
          from: this.timeStampFrom,
          to: this.timeStampTo,
          timeFormat: this.timeFormat,
          autoReport: this.selectorModel.autoReport,
          autoReportMinutes: this.selectorModel.autoReportMinutes
        })
      })
      this.setEntitySelectorItems(items)
      this.setSelectedItem({
        _id: data.thingId,
        name: data.thingName,
        from: this.timeStampFrom,
        to: this.timeStampTo,
        timeFormat: this.timeFormat,
        autoReport: this.selectorModel.autoReport,
        autoReportMinutes: this.selectorModel.autoReportMinutes
      })
      if (!this.tabs.find(tab => tab.id === 'tab-summary-detail')) {
        this.tabs.push({
          id: 'tab-summary-detail',
          name: i18n.t('detail')
        })
      }
      this.commitVisible({ visible: false })
      this.reportDetailModel.loadingSpinner = true
      this.loadingSpinner = false
      this.selectedTab = 'tab-summary-detail'
    },
    fleetClickRow () {
      // método para manejar el click en una fila de la tabla de flotas de camiones
    },
    truckSwitchPercentage () {
      this.truckPercentage = !this.truckPercentage
      if (this.truckPercentage) this.truckTableData.cleanAndUpdate(this.percentageTruckTableData)
      else this.truckTableData.cleanAndUpdate(this.timeTruckTableData)
      this.setSortFunctions()
    },
    fleetSwitchPercentage () {
      this.fleetPercentage = !this.fleetPercentage
      if (this.fleetPercentage) this.fleetTableData.cleanAndUpdate(this.percentageFleetTableData)
      else this.fleetTableData.cleanAndUpdate(this.timeFleetTableData)
      this.setSortFunctions()
    },
    calcsFunction (numbers, id) {
      return statisticFunction(numbers, id)
    },
    truckCalcsFormatter (number) {
      if (!this.truckPercentage) {
        return number < 0 ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(Math.round(number)), this.timeFormat.value) : ReportConversionUtil.secondsToStringFormatted(Math.round(number), this.timeFormat.value)
      } else {
        return number
      }
    },
    fleetCalcsFormatter (number) {
      if (!this.fleetPercentage) {
        return number < 0 ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(Math.round(number)), this.timeFormat.value) : ReportConversionUtil.secondsToStringFormatted(Math.round(number), this.timeFormat.value)
      } else {
        return number
      }
    },
    resetCharts (fleet) {
      const chartComponentRef = fleet ? this.$refs['fleetSummaryReportTrucksCharts'] : this.$refs['truckSummaryReportTrucksCharts']
      if (chartComponentRef) chartComponentRef.resetAll()
    },
    setSortFunctions () {
      if (!this.truckPercentage) {
        this.truckTableHeaders.find(header => header.value === 'onRoute').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.truckTableHeaders.find(header => header.value === 'inHarvestFront').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.truckTableHeaders.find(header => header.value === 'inCanchon').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.truckTableHeaders.find(header => header.value === 'outOfService').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.truckTableHeaders.find(header => header.value === 'movement').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.truckTableHeaders.find(header => header.value === 'stopped').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
      } else {
        delete this.truckTableHeaders.find(header => header.value === 'onRoute').sort
        delete this.truckTableHeaders.find(header => header.value === 'inHarvestFront').sort
        delete this.truckTableHeaders.find(header => header.value === 'inCanchon').sort
        delete this.truckTableHeaders.find(header => header.value === 'outOfService').sort
        delete this.truckTableHeaders.find(header => header.value === 'movement').sort
        delete this.truckTableHeaders.find(header => header.value === 'stopped').sort
      }
      if (!this.fleetPercentage) {
        this.fleetTableHeaders.find(header => header.value === 'onRoute').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.fleetTableHeaders.find(header => header.value === 'inHarvestFront').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.fleetTableHeaders.find(header => header.value === 'inCanchon').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.fleetTableHeaders.find(header => header.value === 'outOfService').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.fleetTableHeaders.find(header => header.value === 'movement').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
        this.fleetTableHeaders.find(header => header.value === 'stopped').sort = (a, b) => { return sortTimeByDay(a, b, this.timeFormat) }
      } else {
        delete this.fleetTableHeaders.find(header => header.value === 'onRoute').sort
        delete this.fleetTableHeaders.find(header => header.value === 'inHarvestFront').sort
        delete this.fleetTableHeaders.find(header => header.value === 'inCanchon').sort
        delete this.fleetTableHeaders.find(header => header.value === 'outOfService').sort
        delete this.fleetTableHeaders.find(header => header.value === 'movement').sort
        delete this.fleetTableHeaders.find(header => header.value === 'stopped').sort
      }
    },
    /**
     * Cada vez que se modifica la selección de un camion, se cargan
    */
    truckSelectedFunction (newValue) {
      this.truckSelectorThingsSelected = newValue && newValue.id ? newValue : null
    },
    /**
     * Cada vez que se modifica la selección de una flota, se cargan
    */
    fleetSelectedFunction (newValue) {
      this.fleetSelectorThingsSelected = newValue && newValue.id ? newValue : null
    },
    /**
     * Carga los selectores a partir de los gráficos generados y carga el primer gráficos (de todos los camiones/flotas)
     */
    initCharts () {
      this.fleetSelectorThings.cleanAndUpdate(this.fleetsCharts.map(element => ({ id: element.id, name: element.name })))
      this.truckSelectorThings.cleanAndUpdate(this.trucksCharts.map(element => ({ id: element.id, name: element.name })))
      if (this.fleetSelectorThings.length > 0) {
        this.fleetSelectorThingsSelected = this.fleetSelectorThings[0]
        this.selectedFleetsCharts.cleanAndUpdate(this.fleetsCharts[0].chartSets)
      } else {
        this.fleetSelectorThingsSelected = null
        this.selectedFleetsCharts.clean()
      }
      this.resetCharts(true)
      if (this.truckSelectorThings.length > 0) {
        this.truckSelectorThingsSelected = this.truckSelectorThings[0]
        this.selectedTrucksCharts.cleanAndUpdate(this.trucksCharts[0].chartSets)
      } else {
        this.truckSelectorThingsSelected = null
        this.selectedTrucksCharts.clean()
      }
      this.resetCharts(false)
    },
    /**
     * Cuando se hace click por primera vez en el tab de procedencia, se ejecuta la request para cargar los datos
     */
    originTabClicked () {
      this.commitVisible({ visible: false })
      if (!this.originTabClickedOneTime) {
        this.commitVisible({ visible: true })
        this.originTableDisableRefreshButton = true
        this.loadingOriginTable = true
        if (this.autoReport) {
          truckApi.getOriginReportDirect(this.thingsId, this.timeStampFrom, this.timeStampTo, this.timeFormat)
            .then(response => {
              const processedData = truckSummaryReportService.processOriginData(response.data, this.timeFormat)
              this.originTableHeaders.cleanAndUpdate(processedData.headers)
              this.originTableData.cleanAndUpdate(processedData.data)
              this.commitVisible({ visible: false })
              this.originTabClickedOneTime = true
              this.originTableDisableRefreshButton = false
              this.loadingOriginTable = false
            })
          const that = this
          clearInterval(that.intervalId)
          that.intervalId = setInterval(
            function () {
              const conversion = getDateTimeRange(that.dateAndTimeRange, that.dateAndTimeRangeCustomType,
                that.sinceDate, that.sinceTime, that.toDate, that.toTime)
              that.timeStampFrom = conversion.tFrom
              that.timeStampTo = conversion.tTo
              that.getDataNotDeferred(true)
            },
            that.autoReportMinutes * 60 * 1000)
        } else {
          // con la misma Key del summary report se obtiene el de procedencia
          truckApi.getOriginReport(this.currentReportKey, this.timeFormat)
            .then(response => {
              const processedData = truckSummaryReportService.processOriginData(response.data, this.timeFormat)
              this.originTableHeaders.cleanAndUpdate(processedData.headers)
              this.originTableData.cleanAndUpdate(processedData.data)
              this.commitVisible({ visible: false })
              this.originTabClickedOneTime = true
              this.originTableDisableRefreshButton = false
              this.loadingOriginTable = false
            })
        }
      }
    },
    summaryTabClicked () {
      this.commitVisible({ visible: false })
    },
    refreshOriginReport () {
      if (this.autoReport) {
        const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
          this.sinceDate, this.sinceTime, this.toDate, this.toTime)
        this.timeStampFrom = conversion.tFrom
        this.timeStampTo = conversion.tTo
        this.getDataNotDeferred(true)
      } else {
        this.originTableDisableRefreshButton = true
        this.loadingOriginTable = true
        this.commitVisible({ visible: true })
        truckApi.getOriginReport(this.currentReportKey, this.timeFormat)
          .then(response => {
            const processedData = truckSummaryReportService.processOriginData(response.data, this.timeFormat)
            this.originTableData.cleanAndUpdate(processedData.data)
            this.commitVisible({ visible: false })
            this.originTableDisableRefreshButton = false
            this.loadingOriginTable = false
          })
      }
    }
  },
  watch: {
    truckSelectorThingsSelected (newValue) {
      if (newValue != null && newValue.id != null) {
        const chart = this.trucksCharts.find(element => element.id === newValue.id)
        if (chart != null && chart.chartSets != null) {
          this.selectedTrucksCharts.cleanAndUpdate(chart.chartSets)
        } else {
          this.selectedTrucksCharts.clean()
        }
      } else {
        this.selectedTrucksCharts.clean()
      }
      this.resetCharts(false)
    },
    fleetSelectorThingsSelected (newValue) {
      if (newValue != null && newValue.id != null) {
        const chart = this.fleetsCharts.find(element => element.id === newValue.id)
        if (chart != null && chart.chartSets != null) {
          this.selectedFleetsCharts.cleanAndUpdate(chart.chartSets)
        } else {
          this.selectedFleetsCharts.clean()
        }
      } else {
        this.selectedFleetsCharts.clean()
      }
      this.resetCharts(true)
    },
    selectorModel: {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
