import i18n from '@/i18n'
import deepcopy from 'deepcopy'
import { getTimeOffset } from '@/tools/functions'
import { ReportConversionUtil, ValueText, Axes, ChartType, Options, Chart, Dataset, Util } from '@colven/common-domain-lib/lib'
import TruckSummaryReportConstants from '@/constants/truckSummaryReportConstants'
// import conversionUtil from '@/utils/conversion.util'
// import reportsUtil from '@/utils/reports.util'

const processReportData = (data, from, to, timeFormat) => {
  const offset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const dataConverted = deepcopy(data)
  convertSeconds(dataConverted.truckData, timeFormat.value)
  convertSeconds(dataConverted.fleetData, timeFormat.value)
  return {
    truckData: dataConverted.truckData,
    percentageTruckData: dataConverted.percentageTruckData,
    truckHeaders: TruckSummaryReportConstants.truckHeaders(lang),
    fleetData: dataConverted.fleetData,
    percentageFleetData: dataConverted.percentageFleetData,
    fleetHeaders: TruckSummaryReportConstants.fleetHeaders(lang),
    truckCharts: generateCharts(data.truckData, false, lang, from, to, offset, timeFormat.value),
    fleetCharts: generateCharts(data.fleetData, true, lang, from, to, offset, timeFormat.value)
  }
}

const processReportDetailData = (data) => {
  const offset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  data.forEach(element => {
    if (element.stateName != null) {
      if (element.stateName[lang] != null) {
        element.stateName = element.stateName[lang]
      } else if (element.stateName.es) {
        element.stateName = element.stateName.es
      } else {
        element.stateName = undefined
      }
    }

    if (element.timestamp != null) {
      const conversion = ReportConversionUtil.applyTimezone(element.timestamp, offset)
      element.timestamp = new ValueText(element.timestamp, `${conversion.dateString} ${conversion.timeString}`)
    }
  })
  return { data, headers: TruckSummaryReportConstants.detailHeaders(lang) }
}

/**
 * Genera todos los gráficos para el reporte
 * @param data
 * @param fleet
 * @param lang
 * @param from
 * @param to
 * @param offset
 * @param timeFormat
 */
const generateCharts = (data, fleet = false, lang, from, to, offset, timeFormat) => {
  const charts = []
  let chartSets

  // primer gráfico con todos los datos:
  chartSets = getChartSet(data, fleet, lang, from, to, offset, timeFormat)
  charts.push({
    id: 'ALL',
    name: i18n.t(`truckSummaryReport.CHARTS.ALL`, { lang }),
    chartSets
  })

  // gráficos por cada camión/flota
  data.forEach(element => {
    chartSets = getChartSet([element], fleet, lang, from, to, offset, timeFormat)
    charts.push({
      id: fleet ? element.fleetId : element.thingId,
      name: fleet ? element.fleetName : element.thingName,
      chartSets
    })
  })

  return charts
}

/**
 * Genera los dos gráficos para un conjunto de datos
 * @param data
 * @param fleet
 * @param lang
 * @param from
 * @param to
 * @param offset
 * @param timeFormat
 * @returns
 */
const getChartSet = (data, fleet = false, lang, from, to, offset, timeFormat) => {
  const yAxesPie = new Axes(ChartType.PIE)
  const xAxesPie = new Axes(ChartType.PIE)
  const yAxesBar = new Axes(ChartType.BAR)
  const xAxesBar = new Axes(ChartType.BAR)
  const optionsPie = new Options()
  const optionsBar = new Options()
  const chartPie = new Chart()
  const chartBar = new Chart()
  // id
  chartPie.id = fleet ? 'truckSummaryReportFleetPieChart' : 'truckSummaryReportTruckPieChart'
  chartBar.id = fleet ? 'truckSummaryReportFleetBarChart' : 'truckSummaryReportTruckBarChart'
  // tipo
  chartPie.type = ChartType.PIE
  chartBar.type = ChartType.BAR
  // dataset
  const datasetPie = new Dataset()
  const datasetBar = new Dataset()
  // datos
  // en ruta, en canchon, en frente de cosecha, fuera de sevicio, movimiento, detenido
  datasetPie.data = [0, 0, 0, 0]
  datasetBar.data = deepcopy(datasetPie.data)
  datasetBar.label = i18n.t(`truckSummaryReport.CHARTS.BAR_DATASET_LABEL`, { lang })
  let total = 0
  data.forEach(d => {
    datasetPie.data[0] += Number(d.onRoute)
    datasetPie.data[1] += Number(d.inHarvestFront)
    datasetPie.data[2] += Number(d.inCanchon)
    datasetPie.data[3] += Number(d.outOfService)
    // datasetPie.data[4] += Number(d.movement)
    // datasetPie.data[5] += Number(d.stopped)
    total += Number(d.onRoute)
    total += Number(d.inHarvestFront)
    total += Number(d.inCanchon)
    total += Number(d.outOfService)
    // total += Number(d.movement)
    // total += Number(d.stopped)
  })
  datasetPie.data.forEach((d, i) => {
    datasetBar.data[i] += total !== 0 ? Util.roundDecimals(((d / total) * 100)) : 0
  })
  // labels
  let totalPie = 0
  datasetPie.data.forEach(d => { totalPie += d })
  let percentageOnRute = Util.roundDecimals((datasetPie.data[0] * 100 / totalPie))
  let percentageInHarvestFront = Util.roundDecimals((datasetPie.data[1] * 100 / totalPie))
  let percentageInCanchon = Util.roundDecimals((datasetPie.data[2] * 100 / totalPie))
  let percentageOutIfService = Util.roundDecimals((datasetPie.data[3] * 100 / totalPie))
  if (!percentageOnRute) percentageOnRute = 0
  if (!percentageInHarvestFront) percentageInHarvestFront = 0
  if (!percentageInCanchon) percentageInCanchon = 0
  if (!percentageOutIfService) percentageOutIfService = 0
  const labelOnRute = ' (' + percentageOnRute + '%)'
  const labelInHarvestFront = ' (' + percentageInHarvestFront + '%)'
  const labelInCanchon = ' (' + percentageInCanchon + '%)'
  const labelOutIfService = ' (' + percentageOutIfService + '%)'
  chartPie.data.labels = [
    i18n.t('truckSummaryReport.HEADERS.ON_ROUTE', { lang }) + labelOnRute,
    i18n.t('truckSummaryReport.HEADERS.IN_HARVEST_FRONT', { lang }) + labelInHarvestFront,
    i18n.t('truckSummaryReport.HEADERS.IN_CANCHON', { lang }) + labelInCanchon,
    i18n.t('truckSummaryReport.HEADERS.OUT_OF_SERVICE', { lang }) + labelOutIfService
    // i18n.t('truckSummaryReport.HEADERS.MOVING', { lang }),
    // i18n.t('truckSummaryReport.HEADERS.STOPPED', { lang }),
  ]
  chartBar.data.labels = deepcopy(chartPie.data.labels)
  datasetPie.backgroundColor = []
  datasetPie.backgroundColor = [
    '#4CAF50',
    '#2196F3',
    '#009688',
    '#FF5722'
    // '#3F51B5',
    // '#FF9800',
  ]
  datasetBar.backgroundColor = deepcopy(datasetPie.backgroundColor)
  datasetPie.formattedLabelData = []
  datasetPie.data.forEach((d, i) => {
    datasetPie.formattedTooltipData.label.push((d < 0
      ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d), timeFormat)
      : ReportConversionUtil.secondsToStringFormatted(Math.abs(d), timeFormat)) + ` (${datasetBar.data[i]}%)`)
    datasetPie.formattedLabelData.push((d < 0
      ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d), timeFormat)
      : ReportConversionUtil.secondsToStringFormatted(Math.abs(d), timeFormat)) + ' ')
  })
  datasetBar.formattedTooltipData.label = deepcopy(datasetPie.formattedTooltipData.label)
  datasetBar.formattedLabelData = deepcopy(datasetPie.formattedLabelData)
  datasetPie.borderColor = datasetPie.backgroundColor
  datasetBar.borderColor = deepcopy(datasetPie.borderColor)
  // Agrego el dataset creado
  chartPie.data.datasets.push(datasetPie)
  chartBar.data.datasets.push(datasetBar)
  // nombre
  chartPie.name = i18n.t(`truckSummaryReport.CHARTS.${fleet ? 'FLEET_PIE_CHART' : 'TRUCK_PIE_CHART'}`, { lang })
  chartBar.name = i18n.t(`truckSummaryReport.CHARTS.${fleet ? 'FLEET_BAR_CHART' : 'TRUCK_BAR_CHART'}`, { lang })
  const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
  const toConversion = ReportConversionUtil.applyTimezone(to, offset)
  const title = fromConversion.dateString + ' ' +
    fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
  optionsPie.title.text = title
  optionsBar.title.text = title
  // opciones
  optionsPie.scales.yAxes.push(yAxesPie)
  optionsPie.scales.xAxes.push(xAxesPie)
  optionsPie.tooltips.mode = 'point'
  // optionsBar.legend = null
  yAxesBar.scaleLabel.labelString = i18n.t(`truckSummaryReport.CHARTS.BAR_Y_AXES`, { lang })
  xAxesBar.scaleLabel.labelString = i18n.t(`truckSummaryReport.CHARTS.BAR_X_AXES`, { lang })
  yAxesBar.id = 'y-axis-0'
  xAxesBar.id = 'x-axis-0'
  optionsBar.scales.yAxes.push(yAxesBar)
  optionsBar.scales.xAxes.push(xAxesBar)
  chartPie.options = optionsPie
  chartBar.options = optionsBar
  // desactivo las datlabels
  chartPie.showDatalabels = true
  chartBar.showDatalabels = false
  return [chartPie, chartBar]
}

const convertSeconds = (data, timeFormat) => {
  data.forEach(d => {
    d.onRoute = {
      text: d.onRoute < 0
        ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.onRoute), timeFormat)
        : ReportConversionUtil.secondsToStringFormatted(d.onRoute, timeFormat),
      value: d.onRoute
    }
    d.inHarvestFront = {
      text: d.inHarvestFront < 0
        ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.inHarvestFront), timeFormat)
        : ReportConversionUtil.secondsToStringFormatted(d.inHarvestFront, timeFormat),
      value: d.inHarvestFront
    }
    d.inCanchon = {
      text: d.inCanchon < 0
        ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.inCanchon), timeFormat)
        : ReportConversionUtil.secondsToStringFormatted(d.inCanchon, timeFormat),
      value: d.inCanchon
    }
    /*
    d.inOwnWorkshop = {
        text: d.inOwnWorkshop < 0 ?
            '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.inOwnWorkshop), timeFormat) :
            ReportConversionUtil.secondsToStringFormatted(d.inOwnWorkshop, timeFormat),
        value: d.inOwnWorkshop,
    }
    d.inContractorWorkshop = {
        text: d.inContractorWorkshop < 0 ?
            '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.inContractorWorkshop), timeFormat) :
            ReportConversionUtil.secondsToStringFormatted(d.inContractorWorkshop, timeFormat),
        value: d.inContractorWorkshop,
    }
    d.outOfServiceEvent = {
        text: d.outOfServiceEvent < 0 ?
            '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.outOfServiceEvent), timeFormat) :
            ReportConversionUtil.secondsToStringFormatted(d.outOfServiceEvent, timeFormat),
        value: d.outOfServiceEvent,
    }
    */
    d.outOfService = {
      text: d.outOfService < 0
        ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.outOfService), timeFormat)
        : ReportConversionUtil.secondsToStringFormatted(d.outOfService, timeFormat),
      value: d.outOfService
    }
    d.movement = {
      text: d.movement < 0
        ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.movement), timeFormat)
        : ReportConversionUtil.secondsToStringFormatted(d.movement, timeFormat),
      value: d.movement
    }
    d.stopped = {
      text: d.stopped < 0
        ? '- ' + ReportConversionUtil.secondsToStringFormatted(Math.abs(d.stopped), timeFormat)
        : ReportConversionUtil.secondsToStringFormatted(d.stopped, timeFormat),
      value: d.stopped
    }
  })
}

/**
 * Procesar los datos del tab 'procedencia'
 * @param data
 * @param timeFormat
 * @param timeOffset
 * @returns
 */
const processOriginData = (data, timeFormat) => {
  const timeOffset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const headers = TruckSummaryReportConstants.originHeaders(lang)
  data.map(d => {
    d.timeInEquipment = ReportConversionUtil.secondsToStringFormatted(d.secondsInEquipment, timeFormat)
    d.disengagementDate = d.disengagement
      ? ReportConversionUtil.applyTimezone(d.disengagement, timeOffset).dateString + ' ' +
        ReportConversionUtil.applyTimezone(d.disengagement, timeOffset).timeString
      : undefined
    d.endDate = d.end
      ? ReportConversionUtil.applyTimezone(d.end, timeOffset).dateString + ' ' +
        ReportConversionUtil.applyTimezone(d.end, timeOffset).timeString
      : undefined
    d.startDate = d.start
      ? ReportConversionUtil.applyTimezone(d.start, timeOffset).dateString + ' ' +
        ReportConversionUtil.applyTimezone(d.start, timeOffset).timeString
      : undefined
    d.dischargeTrackTimestamp = d.dischargeTrackTimestamp
      ? ReportConversionUtil.applyTimezone(d.dischargeTrackTimestamp, timeOffset).dateString + ' ' +
        ReportConversionUtil.applyTimezone(d.dischargeTrackTimestamp, timeOffset).timeString
      : undefined
    return d
  })
  return { data, headers }
}

export default {
  processReportData,
  processReportDetailData,
  processOriginData
}
