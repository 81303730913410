import { render, staticRenderFns } from "./TruckSummaryReportDetail.vue?vue&type=template&id=27ae52f1&scoped=true"
import script from "./TruckSummaryReportDetail.js?vue&type=script&lang=js&external"
export * from "./TruckSummaryReportDetail.js?vue&type=script&lang=js&external"
import style1 from "./TruckSummaryReportDetail.vue?vue&type=style&index=1&id=27ae52f1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ae52f1",
  null
  
)

export default component.exports